import React, { Component } from 'react'
// import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
// import GambarTokopedHampers2 from './img/tokoped_hampers2.jpg'
// import GambarNastar1 from './img/nastar1.jpg'
// import GambarTopples from './img/topples1.jpg'
// import GambarRokaToples from './img/roka_toples.jpg'

function refreshPage(){
  setTimeout(()=>{
    window.location.reload(false);
  }, 500); 
}

export default class Tentang extends Component {
  render() {
    return (
      <div>

        {/* <div className="col-md-12 col-md-offset-0 col-md-pull-0" style={{WebkitTextFillColor:'white', backgroundColor: '#faab00', paddingTop:'3em'}}  > */}
        <div className="col-md-12 col-md-offset-0 col-md-pull-0" style={{WebkitTextFillColor:'white', backgroundColor: '#fdba2a', paddingTop:'1.5em'}}  >

        <div  className='="w3 container' >    
            <div  className='="w3 container' >    

                <span   className="heading-meta">
                <Link style={{WebkitTextFillColor:'black'}} to="/about" className="nav-link" onClick={refreshPage}> About</Link> 
                </span>

                <span className="heading-meta">
                <Link style={{WebkitTextFillColor:'black'}} to="/contact" className="nav-link" onClick={refreshPage}>Contact</Link> 
                </span>

                <span className="heading-meta">
                <Link style={{WebkitTextFillColor:'black'}} to="/privacy-policy" className="nav-link" onClick={refreshPage}>  Privacy&nbsp;Policy</Link>
                </span>

                <span className="heading-meta">
                <Link style={{WebkitTextFillColor:'black'}} to="/disclaimer" className="nav-link" onClick={refreshPage}>  Disclaimer</Link> 
                </span>
            
            </div>
      </div>

      
        
      </div>
      
      </div>
    )
  }
}
